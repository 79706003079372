import { ref, watch, computed } from '@vue/composition-api'
import { title } from '@core/utils/filter'

// Notification
import Vue from 'vue'
import store from '@/store'

export default function useRequestItemList() {
  // Use toast

  // if (Designations) emit('addDesignation')
  const refFamiliesListTable = ref(null)

  // Table Handlers
  const tableColumns = [

    {
      key: 'id',
      label: '  الرقم',

      sortable: true,
    },
    {
      key: 'name',
      label: ' الاسم ',

      sortable: true,
    },
    
    // {
    //   key: 'notes',
    //   label: ' التفاصيل ',

    //   sortable: true,
    // },
    {
      key: 'location',
      label: ' الموقع ',

      sortable: true,
    },
 
    // { key: 'status', sortable: true },
    { key: 'action_id', label: 'الخيارات' },
  ]
  const perPage = ref(10)
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100, 1000]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refFamiliesListTable.value
      ? refFamiliesListTable.value.localItems.length
      : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    }
  })

  const refetchData = () => {
    refFamiliesListTable.value.refresh()
  }
  const selectedItem = ref([])

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  const onFiltered = filteredItems => {
    // Trigger pagination to update the number of buttons/pages due to filtering
    totalUsers.value = filteredItems.length
    currentPage.value = 1
  }

  const fetchWarehouse = (ctx, callback) => {
    store
      .dispatch('app-Warehouse/fetchWarehouse', {

        page_size: perPage.value,

        page: currentPage.value,
      })
      .then(response => {
        //console.log('fetchWarehouse', response.data)
        const { data } = response.data
        // totalUsers.value = response.data.meta.total
        callback(data)
      })
      .catch(() => {
        Vue.swal({
          title: 'حدثت مشكلة في استرجاع البيانات',
          icon: 'error',

          confirmButtonText: 'موافق',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      })
  }

  return {

    fetchWarehouse,
    onFiltered,

    tableColumns,
    perPage,
    currentPage,

    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refFamiliesListTable,

    refetchData,
    selectedItem,
  }
}
